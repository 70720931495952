import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { MatButtonModule } from "@angular/material/button";
import { MatDialogModule } from "@angular/material/dialog";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { ModalModule } from "src/shared/modal";
import { EpochRangeFieldModule } from "../epoch/epoch-range-field/epoch-range-field.module";
import { DateRangePickerComponent } from "./date-range-picker.component";
import { DateRangePickerService } from "./date-range-picker.service";

@NgModule({
  declarations: [DateRangePickerComponent],
  providers: [DateRangePickerService],
  imports: [
    CommonModule,
    MatButtonModule,
    MatDialogModule,
    MatProgressBarModule,
    ModalModule,
    EpochRangeFieldModule,
  ],
})
export class DateRangePickerModule {}
