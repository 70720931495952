import { createEntityAdapter, EntityState } from '@ngrx/entity'
import { createReducer, createSelector, on } from '@ngrx/store'
import { TableKey } from '@tradecafe/types/core'
import { isEqual } from 'lodash-es'
import { InvoiceRow, InvoiceTotalsDto } from 'src/api/invoice'
import { ElasticSearchFilters } from 'src/services/elastic-search'
import { State } from '../reducers'
import { prepareInvoicesPayload } from './invoice-view-filters.utils'
import * as InvoiceViewActions from './invoice-view.actions'

export const invoiceViewsFeatureKey = 'invoiceViews'
export interface InvoiceViewsState extends EntityState<InvoiceRow> {
  filters: Dictionary<ElasticSearchFilters>
  prevQuery: Dictionary<any>

  // invoice ids lists grouped by table key
  tables: Dictionary<string[]>
  // total invoices lists grouped by table key
  totals: Dictionary<InvoiceTotalsDto>
}
const selectId = invoice => invoice.invoice_id
const adapter = createEntityAdapter<InvoiceRow>({ selectId })

const initialState: InvoiceViewsState = adapter.getInitialState({
  tables: {},
  totals: {},
  filters: {},
  prevQuery: {},
})


export const invoiceViewReducer = createReducer(
  initialState,
  on(InvoiceViewActions.loadInvoiceViews,
    (state, action) => {
      const key = action.tableKey
      const query = prepareInvoicesPayload(action.filters, action.page)
      const prev = state.prevQuery[key] || {}

      let { tables, totals, prevQuery } = state
      if (!isEqual(query.query, prev.query)) {
        tables = { ...tables, [key]: undefined } // drop table
        totals = { ...totals, [key]: undefined } // clear total
        prevQuery = { ...prevQuery, [key]: query } // save query
      } else if (!isEqual(query.sort, prev.sort)) {
        const table = [...tables[key] || []]
        tables = { ...tables, [key]: table.fill(undefined) } // clear table
        prevQuery = { ...prevQuery, [key]: query } // save query
      }
      return { ...state, tables, totals, prevQuery }
    }),
  on(InvoiceViewActions.loadInvoiceViewsSuccess,
    (state, action) => {
      const key = action.tableKey
      const { total, totals: totals_, invoiceViews, payload: { skip, limit } } = action
      const invoiceIds = invoiceViews.map(selectId)

      let table = Array.from<string>({ length: total }).fill(undefined)
      table.splice(skip, invoiceIds.length, ...invoiceIds) // NOTE: invoiceIds.length or limit?
      const tables = { ...state.tables, [key]: table }
      const totals = { ...state.totals, [key]: totals_ }

      state = adapter.upsertMany(action.invoiceViews, state)
      return { ...state, tables, totals }
    }),
  on(InvoiceViewActions.loadInvoiceFiltersSuccess,
    (state, action) => {
      const key = action.tableKey
      const filters = { ...state.filters, [key]: action.result }
      return { ...state, filters }
    }),
)

const selectState = (state: State) => state[invoiceViewsFeatureKey]
export const { selectEntities: selectInvoiceViewEntities } = adapter.getSelectors(selectState)

export const selectInvoice = createSelector(
  selectInvoiceViewEntities,
  (invoiceViews: Dictionary<InvoiceRow>, invoiceId: string) =>
    invoiceViews[invoiceId])

export const selectInvoicesByIds = createSelector(
  selectInvoiceViewEntities,
  (invoiceViews: Dictionary<InvoiceRow>, invoiceIds: string[]) =>
    invoiceIds.map(invoiceId => invoiceViews[invoiceId]))

const selectTables = createSelector(selectState, state => state.tables)

export const selectInvoicesFilters = createSelector(
  selectState,
  (state: InvoiceViewsState, key: TableKey) => state.filters[key],
)

export const selectInvoicesTotals = createSelector(
  selectState,
  (state: InvoiceViewsState, key: TableKey) => state.totals[key],
)

export const selectInvoicesTable = createSelector(
  selectInvoiceViewEntities,
  selectTables,
  (invoiceViews: Dictionary<InvoiceRow>, tables: Dictionary<string[]>, key: TableKey) =>
    tables[key] ? tables[key].map(id => invoiceViews[id]) : undefined)

